<script>
import AuthService from "@/api/services/auth.service.js";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import AccessLevel from "@/shared/enums/aceessLevels.js";
import DataRecordsImportTypeCategorizationWidget from "../components/widgets/data-records-import-type-categorization.vue";
import DataRecordsPerCalendarWeekWidget from "../components/widgets/data-records-per-calendar-week.vue";
import DataRecordsPerQuarterWidget from "../components/widgets/data-records-per-quarter.vue";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error"

export default {
  page: {
    title: "KPI Stats",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    DataRecordsImportTypeCategorizationWidget,
    DataRecordsPerCalendarWeekWidget,
    DataFetchingErrorWidget,
    DataRecordsPerQuarterWidget
  },
  data() {
    return {
      /***** Data variables *****/
      loggedInUser: null,
      accessLevelEnum: AccessLevel,
      /****** Operational variable ******/
      isPageLoading: false,
      dataFetchingError: false
    }
  },
  computed: {
    computedItems() {
      var items = [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "DE Data Records Stats",
          active: true
        }
      ];
      return items;
    }
  },
  async mounted() {
    await this.getLoginUser();
  },
  methods: {
    async getLoginUser() {
      // 1. Enable Page Loading 
      this.isPageLoading = true

      // 2. Fetch loggedIn user Data
      try {
        this.loggedInUser = await AuthService.getLoggedInUser();
        this.isPageLoading = false
      } catch(error) {
        this.isPageLoading = false
        this.dataFetchingError = true
      }
    }
  }
}
</script>

<template>
  <Layout>
    <!-- Page Header -->
    <PageHeader
      title="DE Data Records Stats"
      :items="computedItems"
    />

    <!-- Main Page Loader-->
    <div v-if="isPageLoading">
      <div class="row justify-content-center mt-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Main Data fetching Error -->
    <div v-if="!isPageLoading && dataFetchingError">
      <DataFetchingErrorWidget/>
    </div>

    <!-- Display Data -->
    <div v-if="!isPageLoading && loggedInUser.accessLevel === accessLevelEnum.SUPER_ADMIN">
      <!-- Data Records Per Calendar Week-->
      <div class="row">
        <div class="col-md-12">
          <DataRecordsPerCalendarWeekWidget/>
        </div>
      </div>

      <!-- Data Records Import Type Categorization-->
      <div class="row">
        <div class="col-md-12">
          <DataRecordsImportTypeCategorizationWidget/>
        </div>
      </div>

      <!-- Data Records Per Quarter -->
      <div class="row">
        <div class="col-md-12">
          <DataRecordsPerQuarterWidget/>
        </div>
      </div>
    </div>

    <!-- Display Error for Non Super Admin users -->
    <div
      class="row pt-5"
      v-if="loggedInUser.accessLevel !== accessLevelEnum.SUPER_ADMIN"
    >
      <div class="col-md-3"></div>
      <div class="col-md-6 text-center">
        <img src="@/assets/images/no-access.png" alt height="50" />
        <div class="mt-3">
          {{ $t("adminPanel.noPermision") }}
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
  </Layout>
</template>
