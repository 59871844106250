<script>
import StatsService from "@/api/services/stats.service";
import Multiselect from "vue-multiselect";
import { dataRecordsPerQuarterChart } from "@/redem/stats/result-charts.js";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error"

export default {
  components: {
    Multiselect,
    DataFetchingErrorWidget
  },
  data() {
    return {
      dataRecordsPerQuarterChartData: null,
      dataRecordsPerQuarterChartOptions: null,
      /****** Filter Options *****/
      selectedYear: null,
      years: [2021, 2022, 2023, 2024],
      /****** Operational Data *****/
      isComponentLoading: false,
      errorInFetchingData: false
    }
  },
  async mounted() {
    // 1. Create chart option
    this.dataRecordsPerQuarterChartOptions = new dataRecordsPerQuarterChart()

    // 2. Set selectedYear
    this.selectedYear = new Date().getFullYear()

    // 3. Fetching data
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        // 1. Enable loading
        this.isComponentLoading = true

        // 2. Fetch Data
        const response = await StatsService.getNumberOfDataRecordsPerQuarter(this.selectedYear)

        console.log("response: ", response)

        // 3. Make Chart Data variables to default values 
        this.dataRecordsPerQuarterChartData = [
          {
            name: "Number of Total Data Records",
            data: []
          },
          {
            name: "Number of Bad Data Records (R-Score < 60)",
            data: []
          }
        ]

        // 4. Set Data for the Chart
        if(response && response.length > 0) {
          console.log("Reponse: ",response )
          for(let i=0; i < response.length; i++) {
            this.dataRecordsPerQuarterChartOptions.chartOptions.yaxis.title.text = "Number of Data Records"
            this.dataRecordsPerQuarterChartData[0].data.push(response[i].numberOfTotalDataRecords)
            this.dataRecordsPerQuarterChartData[1].data.push(response[i].numberOfBadDataRecords)
          }
        }
        
        // 5. Enable loading
        this.isComponentLoading = false
      } catch(error) {
        this.isComponentLoading = false
        this.errorInFetchingData = true
      }
    },
    async changeSelectedYear() {
      await this.fetchData()
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <div class="row">
          <div class="col-md-10">
            Number of Data Records Per Quarter
          </div>
          <div class="col-md-2">
             <multiselect
              v-model="selectedYear"
              deselect-label="Can't remove this value"
              :options="years"
              :searchable="false"
              :close-on-select="true"
              :allowEmpty="false"
              placeholder="DE Pick a value"
              @input="changeSelectedYear()"
            >
            </multiselect>
          </div>
        </div>
      </div>

      <!-- Display Chart -->
      <div class="card-text" v-if="!isComponentLoading">
         <apexchart
          ref="distributionChart"
          class="apex-charts"
          dir="ltr"
          type="bar"
          height="300"
          :series="dataRecordsPerQuarterChartData"
          :options="dataRecordsPerQuarterChartOptions.chartOptions"
        ></apexchart>
      </div>

      <!-- Display Data Fetching Error -->
      <div class="card-text" v-if="!isComponentLoading && errorInFetchingData">
        <DataFetchingErrorWidget/>
      </div>

      <!-- Display Loading -->
      <div class="card-text" v-if="isComponentLoading">
        <div class="row justify-content-center mt-5">
          <b-spinner
            style="width: 5rem; height: 5rem"
            class="m-2"
            variant="mid"
            role="status"
          ></b-spinner>
        </div>
      </div>
      
    </div>
  </div>
</template>