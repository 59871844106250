var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "card-title" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-10" }, [
            _vm._v(" Number of Respondent Per Calendar Week "),
          ]),
          _c(
            "div",
            { staticClass: "col-md-2" },
            [
              _c("multiselect", {
                attrs: {
                  "deselect-label": "Can't remove this value",
                  options: _vm.years,
                  searchable: false,
                  "close-on-select": true,
                  allowEmpty: false,
                  placeholder: "DE Pick a value",
                },
                on: {
                  input: function ($event) {
                    return _vm.changeSelectedYear()
                  },
                },
                model: {
                  value: _vm.selectedYear,
                  callback: function ($$v) {
                    _vm.selectedYear = $$v
                  },
                  expression: "selectedYear",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      !_vm.isComponentLoading
        ? _c(
            "div",
            { staticClass: "card-text" },
            [
              _c("apexchart", {
                ref: "distributionChart",
                staticClass: "apex-charts",
                attrs: {
                  dir: "ltr",
                  type: "bar",
                  height: "300",
                  series: [_vm.dataPerCalendarWeekChartData],
                  options: _vm.dataPerCalendarWeekChartOptions.chartOptions,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.isComponentLoading && _vm.errorInFetchingData
        ? _c(
            "div",
            { staticClass: "card-text" },
            [_c("DataFetchingErrorWidget")],
            1
          )
        : _vm._e(),
      _vm.isComponentLoading
        ? _c("div", { staticClass: "card-text" }, [
            _c(
              "div",
              { staticClass: "row justify-content-center mt-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }