var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: "DE Data Records Stats", items: _vm.computedItems },
      }),
      _vm.isPageLoading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "row justify-content-center mt-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isPageLoading && _vm.dataFetchingError
        ? _c("div", [_c("DataFetchingErrorWidget")], 1)
        : _vm._e(),
      !_vm.isPageLoading &&
      _vm.loggedInUser.accessLevel === _vm.accessLevelEnum.SUPER_ADMIN
        ? _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [_c("DataRecordsPerCalendarWeekWidget")],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [_c("DataRecordsImportTypeCategorizationWidget")],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [_c("DataRecordsPerQuarterWidget")],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.loggedInUser.accessLevel !== _vm.accessLevelEnum.SUPER_ADMIN
        ? _c("div", { staticClass: "row pt-5" }, [
            _c("div", { staticClass: "col-md-3" }),
            _c("div", { staticClass: "col-md-6 text-center" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/no-access.png"),
                  alt: "",
                  height: "50",
                },
              }),
              _c("div", { staticClass: "mt-3" }, [
                _vm._v(" " + _vm._s(_vm.$t("adminPanel.noPermision")) + " "),
              ]),
            ]),
            _c("div", { staticClass: "col-md-3" }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }